import React, { useEffect, useState, useCallback } from "react"
import { Link } from "gatsby"
import Layout from '../components/Layout'
import Hero from '../components/widgets/Hero' 
import useScript from "../hooks/useScript"

export default function Vacancy({location}) {

  const job = location?.state?.job;

  const [response, setResponse] = useState(false);
  const [gotError, setGotError] = useState(false);

  //load in the Greenhouse script via our useScript hook  
  const status = useScript(
    "https://boards.greenhouse.io/embed/job_board/js?for=landbay"
  );

  const handleResponse = useCallback(async (response) => {
	
	if (response.ok) {
        const content = await response.json();
	    setResponse(content);		
	} else {
		setGotError(true);
	}
	
	//scrollToResults();
  }, [setResponse, setGotError]);

  const loadJob = useCallback(async (jobid) => {
	
	let apiUrl = process.env.GATSBY_GREENHOUSE_API || "https://boards-api.greenhouse.io/v1/boards/landbay/jobs/"+jobid;
	
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      handleResponse(response);

    } catch (err) {
      console.log('caught error');
      console.log(err);
      handleResponse(false);
    }
  }, [handleResponse]);


  /*if we've just navigated from the product range widget we might want to select a product range*/
  useEffect(() => {
	if (job) {
      loadJob(job);
	}
  }, [loadJob, job]);
  
  //when the API and job has loaded
  useEffect(() => {
	if (response.id && status==="ready" && typeof window !=='undefined' && window.Grnhse) {
		console.log('greenhouse loaded ok');
		window.Grnhse.Iframe.load(response.id);
	} else {
		console.log('greenhouse not loaded');
	}
  }, [response, status]);

  //taken from https://github.com/richeyb/sample-job-board/blob/master/src/components/Job.js
  const fixData = (data) => {
    return data
	    .replace(/&amp;/gi, '&')
	    .replace(/&lt;/gi, '<')
	    .replace(/&gt;/gi, '>')
	    .replace(/&amp;nbsp;/gi, ' ')
	    .replace(/&amp;rsquo;/gi, "'")
	    .replace(/href=""([^"]+)""/g, 'href="$1"');
  };

  return (
    <Layout className="vacancy">	
      {/*<Seo post={page} />*/}
	  <div className="page-widgets">
	    <Hero widget={{layout : 'mini'}} />
	  </div>
	  <article className="internal">
		{
		  gotError
		  ? <div className="got-error">
			  <p>There was a technical error retrieving the job</p>
			  <p>Please try again later</p>
			</div>
		  : response
			? response?.id
			  ? <>
				  <header>
					<h1>{response.title}</h1>
					{/*<p dangerouslySetInnerHTML={{ __html: response.content }} />*/}
				  </header>
				  <section className="location-type">
					{
					  response.location?.name
					  ? <p><strong>Location:</strong> {response.location.name}</p>
					  : null
					}

					{/*vacancy.careers.vacancyType && (
					  <p><strong>Type:</strong> {vacancy.careers.vacancyType}</p>
				    )*/}
				  </section>
				  <section className="details" dangerouslySetInnerHTML={{ __html: fixData(response.content) }} />
		
				  <div id="grnhse_app"></div>
				</>
			  : <p>Error retrieving job</p>				
			: <p className="loading"><span></span></p>	  
		}
		{
		  !job
		  ? <>
			  <p className="no-job">No job selected. Please choose a job from our careers page</p>
			  <p className="buttonp"><Link to="/careers/" className="button purple solid">Careers</Link></p>
			</>
		  : null
		}
	  </article>
    </Layout>
  )
}
